<template>
  <div class="box has-background-light">
    <div class="content">
      <h5 class="subtitle has-text-grey is-capitalized">
        search in
      </h5>
    </div>

    <fieldset>
      <b-field>
        <b-checkbox v-model="accounts">
          Accounts
        </b-checkbox>
      </b-field>

      <b-field>
        <b-checkbox v-model="activities">
          Activities
        </b-checkbox>
      </b-field>

      <b-field>
        <b-checkbox v-model="contacts">
          Contacts
        </b-checkbox>
      </b-field>

      <b-field>
        <b-checkbox v-model="documents">
          Documents(filenames)
        </b-checkbox>
      </b-field>

      <b-field>
        <b-checkbox v-model="leads">
          Leads
        </b-checkbox>
      </b-field>

      <b-field>
        <b-checkbox v-model="opportunities">
          Opportunities
        </b-checkbox>
      </b-field>

      <b-field>
        <b-checkbox v-model="users">
          Users
        </b-checkbox>
      </b-field>

      <b-field>
        <b-checkbox v-model="teams">
          Teams
        </b-checkbox>
      </b-field>

      <b-field>
        <b-checkbox v-model="divisions">
          Divisions
        </b-checkbox>
      </b-field>
    </fieldset>
  </div>
</template>

<script>
export default {
  name: 'SearchSidebar',

  computed: {
    accounts: {
      get() {
        return this.$store.state.Search.advancedSearchParams.accounts;
      },

      set(value) {
        return this.$store.commit('Search/SET_ADVANCED_SEARCH_PARAMS_ACCOUNTS', value);
      },
    },

    activities: {
      get() {
        return this.$store.state.Search.advancedSearchParams.activities;
      },

      set(value) {
        return this.$store.commit('Search/SET_ADVANCED_SEARCH_PARAMS_ACTIVITIES', value);
      },
    },

    contacts: {
      get() {
        return this.$store.state.Search.advancedSearchParams.contacts;
      },

      set(value) {
        return this.$store.commit('Search/SET_ADVANCED_SEARCH_PARAMS_CONTACTS', value);
      },
    },

    documents: {
      get() {
        return this.$store.state.Search.advancedSearchParams.documents;
      },

      set(value) {
        return this.$store.commit('Search/SET_ADVANCED_SEARCH_PARAMS_DOCUMENTS', value);
      },
    },

    leads: {
      get() {
        return this.$store.state.Search.advancedSearchParams.leads;
      },

      set(value) {
        return this.$store.commit('Search/SET_ADVANCED_SEARCH_PARAMS_LEADS', value);
      },
    },

    opportunities: {
      get() {
        return this.$store.state.Search.advancedSearchParams.opportunities;
      },

      set(value) {
        return this.$store.commit('Search/SET_ADVANCED_SEARCH_PARAMS_OPPORTUNITIES', value);
      },
    },

    users: {
      get() {
        return this.$store.state.Search.advancedSearchParams.users;
      },

      set(value) {
        return this.$store.commit('Search/SET_ADVANCED_SEARCH_PARAMS_USERS', value);
      },
    },

    teams: {
      get() {
        return this.$store.state.Search.advancedSearchParams.teams;
      },

      set(value) {
        return this.$store.commit('Search/SET_ADVANCED_SEARCH_PARAMS_TEAMS', value);
      },
    },

    divisions: {
      get() {
        return this.$store.state.Search.advancedSearchParams.divisions;
      },

      set(value) {
        return this.$store.commit('Search/SET_ADVANCED_SEARCH_PARAMS_DIVISIONS', value);
      },
    },

  },
};
</script>

<style>

</style>
