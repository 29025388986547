<template>
  <b-autocomplete
    v-if="false"
    v-model="searchClause"
    icon-right="search"
    field="title"
    :open-on-focus="true"
    :data="searchResults"
    :loading="isLoading"
    max-height="2000px"
    expanded
    @typing="getAsyncData"
    @select="(option) => (selected = option)"
  >
    <template slot-scope="props">
      <div
        v-for="(searchResult, index) in props"
        :key="index"
      >
        <div
          v-for="(category, categoryIndex) in Object.keys(searchResult)"
          :key="categoryIndex"
        >
          <template v-if="Object.values(searchResult)[categoryIndex].length > 0">
            <template v-for="(result, resultIndex) in Object.values(searchResult)[categoryIndex]">
              <div :key="resultIndex">
                <template v-if="category === 'users'">
                  <div class="media">
                    <figure class="media-left is-32x32">
                      <img
                        style="width: 32px; height: 32px;"
                        class="is-rounded"
                        :src="result.userPhoto ? result.userPhoto : defaultImage"
                        :alt="result.firstName"
                      >
                    </figure>
                    <div class="media-content">
                      <router-link
                        class="has-text-info has-text-weight-semibold"
                        :to="{
                          name: 'EditUser',
                          params: { userId: result.userId },
                        }"
                      >
                        {{ `${result.firstName} ${result.lastName}` }}
                      </router-link>
                    </div>
                  </div>
                </template>

                <template v-if="category === 'contacts'">
                  <div class="media">
                    <figure class="media-left is-32x32">
                      <img
                        style="width: 32px; height: 32px;"
                        class="is-rounded"
                        :src="result.photo ? result.photo : defaultImage"
                        :alt="result.firstName"
                      >
                    </figure>
                    <div class="media-content">
                      <router-link
                        class="has-text-info has-text-weight-semibold"
                        :to="{
                          name: 'ViewContact',
                          params: { contactId: result.contactId },
                        }"
                      >
                        {{ `${result.firstName} ${result.lastName}` }}
                      </router-link>
                    </div>
                  </div>
                </template>

                <template v-if="category === 'activities'">
                  <router-link
                    class="has-text-info has-text-weight-semibold"
                    :to="{
                      name: 'EditActivity',
                      params: { activityId: result.activityId },
                    }"
                  >
                    {{ result.activityName }}
                  </router-link>
                </template>

                <template v-if="category === 'accounts'">
                  <router-link
                    class="has-text-info has-text-weight-semibold"
                    :to="{
                      name: 'ViewAccount',
                      params: { accountId: result.accountId },
                    }"
                  >
                    {{ result.name }}
                  </router-link>
                </template>

                <template v-else>
                  <strong class="has-text-info has-text-weight-semibold">
                    {{ result.name }}
                  </strong>
                </template>
              </div>
            </template>
          </template>
        </div>
      </div>
    </template>

    <template
      v-if="searchResults.length > 0"
      slot="footer"
    >
      <b-button
        type="is-primary"
        @click="$router.push('/search')"
      >
        view results
      </b-button>
    </template>

    <template slot="empty">
      No results for {{ searchClause }}
    </template>
  </b-autocomplete>
</template>

<script>
import { mapGetters } from 'vuex';

import defaultImage from '@/assets/images/avatar.svg';

export default {
  name: 'SearchBar',

  data: () => ({
    defaultImage,
  }),

  computed: {
    ...mapGetters({
      isLoading: 'Search/getLoading',
      searchResults: 'Search/getSearchResults',
    }),

    searchClause: {
      get() {
        return this.$store.state.Search.searchClause;
      },
      set(value) {
        return this.$store.commit('Search/SET_SEARCH_CLAUSE', value);
      },
    },
  },

  methods: {
    getAsyncData() {
      this.$store.dispatch('Search/search', this.searchClause);
      // eslint-disable-next-line func-names
      setTimeout(function () {
        this.getAsyncData();
      }, 5000);
    },
  },
};
</script>

<style></style>
