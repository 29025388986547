var render = function render(){var _vm=this,_c=_vm._self._c;return (false)?_c('b-autocomplete',{attrs:{"icon-right":"search","field":"title","open-on-focus":true,"data":_vm.searchResults,"loading":_vm.isLoading,"max-height":"2000px","expanded":""},on:{"typing":_vm.getAsyncData,"select":(option) => (_vm.selected = option)},scopedSlots:_vm._u([{key:"default",fn:function(props){return _vm._l((props),function(searchResult,index){return _c('div',{key:index},_vm._l((Object.keys(searchResult)),function(category,categoryIndex){return _c('div',{key:categoryIndex},[(Object.values(searchResult)[categoryIndex].length > 0)?[_vm._l((Object.values(searchResult)[categoryIndex]),function(result,resultIndex){return [_c('div',{key:resultIndex},[(category === 'users')?[_c('div',{staticClass:"media"},[_c('figure',{staticClass:"media-left is-32x32"},[_c('img',{staticClass:"is-rounded",staticStyle:{"width":"32px","height":"32px"},attrs:{"src":result.userPhoto ? result.userPhoto : _vm.defaultImage,"alt":result.firstName}})]),_c('div',{staticClass:"media-content"},[_c('router-link',{staticClass:"has-text-info has-text-weight-semibold",attrs:{"to":{
                        name: 'EditUser',
                        params: { userId: result.userId },
                      }}},[_vm._v(" "+_vm._s(`${result.firstName} ${result.lastName}`)+" ")])],1)])]:_vm._e(),(category === 'contacts')?[_c('div',{staticClass:"media"},[_c('figure',{staticClass:"media-left is-32x32"},[_c('img',{staticClass:"is-rounded",staticStyle:{"width":"32px","height":"32px"},attrs:{"src":result.photo ? result.photo : _vm.defaultImage,"alt":result.firstName}})]),_c('div',{staticClass:"media-content"},[_c('router-link',{staticClass:"has-text-info has-text-weight-semibold",attrs:{"to":{
                        name: 'ViewContact',
                        params: { contactId: result.contactId },
                      }}},[_vm._v(" "+_vm._s(`${result.firstName} ${result.lastName}`)+" ")])],1)])]:_vm._e(),(category === 'activities')?[_c('router-link',{staticClass:"has-text-info has-text-weight-semibold",attrs:{"to":{
                    name: 'EditActivity',
                    params: { activityId: result.activityId },
                  }}},[_vm._v(" "+_vm._s(result.activityName)+" ")])]:_vm._e(),(category === 'accounts')?[_c('router-link',{staticClass:"has-text-info has-text-weight-semibold",attrs:{"to":{
                    name: 'ViewAccount',
                    params: { accountId: result.accountId },
                  }}},[_vm._v(" "+_vm._s(result.name)+" ")])]:[_c('strong',{staticClass:"has-text-info has-text-weight-semibold"},[_vm._v(" "+_vm._s(result.name)+" ")])]],2)]})]:_vm._e()],2)}),0)})}}],null,false,2437163143),model:{value:(_vm.searchClause),callback:function ($$v) {_vm.searchClause=$$v},expression:"searchClause"}},[(_vm.searchResults.length > 0)?_c('template',{slot:"footer"},[_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.$router.push('/search')}}},[_vm._v(" view results ")])],1):_vm._e(),_c('template',{slot:"empty"},[_vm._v(" No results for "+_vm._s(_vm.searchClause)+" ")])],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }