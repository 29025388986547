<template>
  <fieldset>
    <b-field>
      <b-input v-model="searchClause" />
    </b-field>
  </fieldset>
</template>

<script>
export default {

  name: 'SearchAdvancedForm',

  computed: {
    searchClause: {
      get() {
        return this.$store.state.Search.searchClause;
      },

      set(value) {
        return this.$store.commit('Search/SET_SEARCH_CLAUSE', value);
      },
    },
  },

};
</script>

<style>

</style>
