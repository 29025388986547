<template>
  <b-table
    :data="opportunities"
    :narrowed="true"
    striped
  >
    <b-table-column
      v-slot="props"
      label="Actions"
      custom-key="actions"
    >
      <b-tooltip
        type="is-info"
        label="Edit"
        position="is-bottom"
      >
        <b-button
          type="is-text"
          class="has-text-grey"
          icon-right="pencil"
          tag="router-link"
          :to="{ name: 'EditOpportunity', params: { opportunityId: props.row.opportunityId } }"
        />
      </b-tooltip>

      <b-tooltip
        type="is-info"
        label="Email"
        position="is-bottom"
      >
        <b-button
          type="is-text"
          class="has-text-grey"
          icon-right="email"
        />
      </b-tooltip>
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Opportunity"
      field="id"
    >
      <router-link
        class="has-text-info has-text-weight-bold"
        :to="{ name: 'ViewOpportunity', params: { opportunityId: props.row.opportunityId } }"
      >
        {{ props.row.formattedName }}
      </router-link>
    </b-table-column>

    <b-table-column
      label="Name"
      field="name"
      sortable
      searchable
    >
      <template
        slot="searchable"
        slot-scope="props"
      >
        <b-input
          v-model="props.filters['name']"
          size="is-small"
          placeholder="Search..."
          icon="search"
        />
      </template>

      <template v-slot="props">
        {{ props.row.name }}
      </template>
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Contact"
      field="primaryContact"
    >
      <b-tag v-if="props.row.primaryContact">
        {{ props.row.primaryContact.firstName }} {{ props.row.primaryContact.lastName }}
      </b-tag>
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Close Date"
      field="dateClosed"
    >
      {{ props.row.dateClosed }}
    </b-table-column>

    <b-table-column
      label="Est. Revenue"
      field="estimatedRevenue"
      sortable
      searchable
    >
      <template
        slot="searchable"
        slot-scope="props"
      >
        <b-input
          v-model="props.filters['estimatedPotentials.estimatedRevenue']"
          size="is-small"
          placeholder="Search..."
          icon="search"
        />
      </template>

      <template v-slot="props">
        {{ props.row.estimatedPotentials.estimatedRevenue | currency }}
      </template>
    </b-table-column>

    <b-table-column
      v-slot="props"
      centered
      label="Probability"
      field="estimatedPotentials.probability"
    >
      {{
        props.row.estimatedPotentials.probability ? props.row.estimatedPotentials.probability : 0
      }}&#37;
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Stage"
      field="stageId"
    >
      <div class="columns is-gapless is-hidden-mobile">
        <div
          v-for="(stage, index) in stages"
          :key="index"
          class="column stages"
        >
          <div
            :class="{
              'stage-item': true,
              'is-small': true,
              'is-success': stage.orderSequence < props.row.stage.orderSequence,
              'is-primary': stage.code === props.row.stage.code,
            }"
          />
        </div>
      </div>
      <b-tag class="is-hidden-desktop">
        {{ props.row.stage.value }}
      </b-tag>
    </b-table-column>
  </b-table>
</template>

<script>
export default {
  name: 'OpportunitiesTable',

  filters: {
    date(value) {
      return new Date(value).toLocaleDateString();
    },
  },

  props: {
    opportunities: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="css" scoped></style>
