<template>
  <b-table
    :data="users"
    :narrowed="true"
    striped
  >
    <b-table-column
      v-slot="props"
      label="Name"
      field="fullName"
    >
      <p class="has-text-grey has-text-weight-bold">
        {{ props.row.firstName }} {{ props.row.lastName }}
      </p>
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Email"
      field="email"
    >
      {{ props.row.email }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Phone"
      field="phoneCell"
    >
      {{ props.row.phoneCell }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      custom-key="actions"
    >
      <div class="buttons">
        <b-tooltip
          v-if="currentRole === 'ROLE_BUSINESS_ADMIN' || currentRole === 'ROLE_SALES_MANAGER'"
          type="is-info"
          label="goals"
          position="is-bottom"
        >
          <b-button
            type="is-text"
            class="has-text-grey"
            icon-right="dollar-sign"
            @click="$emit('add-user-goal', props.row.userId)"
          />
        </b-tooltip>

        <b-tooltip
          v-if="currentRole === 'ROLE_BUSINESS_ADMIN'"
          type="is-info"
          label="Edit"
          position="is-bottom"
        >
          <b-button
            type="is-text"
            class="has-text-grey"
            icon-right="pencil"
            tag="router-link"
            :to="{ name: 'EditUser', params: { userId: props.row.userId } }"
          />
        </b-tooltip>

        <b-tooltip
          type="is-info"
          label="Email"
          position="is-bottom"
        >
          <a :href="`mailto:${props.row.email}`">
            <b-icon
              icon="email"
              class="has-text-grey"
            />
          </a>
        </b-tooltip>
      </div>
    </b-table-column>
  </b-table>
</template>

<script>
import defaultImage from '@/assets/images/avatar.svg';

export default {
  name: 'UsersTable',

  filters: {
    role(value) {
      return value.replace(/_/g, ' ').substring(5).toLowerCase();
    },
  },

  props: {
    users: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    defaultImage,
    currentRole: localStorage.getItem('role'),
  }),
};
</script>

<style lang="css" scoped>

</style>
